import React, { memo, useMemo } from "react"
import { Box, Container, Heading, HStack, IconButton, Text, VStack } from "@chakra-ui/react"

import { withSection } from "@app/hoc/Section"
import { useCore } from "@app/hooks/useCore"
import { useSanityContent } from "@app/hooks/useSanity"

import type { Section } from "@root/types/global"
import { Link } from "@app/components/Link"
import { Icon } from "@app/components/Icon"
import { graphql, useStaticQuery } from "gatsby"

type Props = Section & GatsbyTypes.SanitySectionText

const SectionText: React.FC<Props> = withSection(
  memo(({ content: rawContent, subtitle: rawSubtitle, tag, title: rawTitle, includeSocialChannels }) => {
    const { social } = useStaticQuery<GatsbyTypes.StaticFooterSocialMediaQuery>(graphql`
      query StaticFooterSocialMedia {
        social: sanitySettingSocial {
          channels {
            key: _key
            title
            url
          }
          additionalFooter
        }
      }
    `)
    const {
      helpers: { linebreak, handleize },
    } = useCore()
    const { sanityContent } = useSanityContent()

    const content = useMemo(
      () => rawContent?.map(item => ({ ...item, key: item?._key, content: sanityContent(item?.content) })) || [],
      [rawContent, sanityContent]
    )

    const subtitle = useMemo(() => linebreak(rawSubtitle), [linebreak, rawSubtitle])
    const title = useMemo(() => linebreak(rawTitle), [linebreak, rawTitle])
    const icons = useMemo(() => ["facebook", "instagram", "youtube", "tiktok"], [])
    const socialChannels = useMemo(
      () =>
        social?.channels?.map(item => ({
          ...item,
          handle: handleize(item?.title),
          icon: icons?.includes(handleize(item?.title)),
        })),
      [handleize, icons, social?.channels]
    )

    return (
      <Box as="section" textAlign="center">
        <Box py={10}>
          {(title || subtitle || content?.length > 0) && (
            <Container as={VStack} alignItems="stretch" justifyContent="flex-start" maxW="container.sm" px={10.5} spacing={12.5}>
              {title && (
                <Heading
                  as={tag}
                  dangerouslySetInnerHTML={{ __html: title }}
                  size="mainHeading"
                  paddingBottom={subtitle || socialChannels ? 4 : 0}
                />
              )}
              {subtitle && (
                <Heading
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                  size="subHeading"
                  maxW="100%"
                  marginTop="0 !important"
                  fontSize={12}
                  fontWeight={500}
                  letterSpacing={2.5}
                  paddingBottom={socialChannels ? 4 : 0}
                  textTransform="uppercase"
                />
              )}
              {includeSocialChannels && socialChannels && (
                <HStack alignItems="center" justifyContent="center" spacing={5} marginTop="0 !important">
                  {socialChannels?.map(
                    ({ key, icon, title, url }) =>
                      title &&
                      url &&
                      icon && (
                        <IconButton
                          as={Link}
                          key={key}
                          display="block"
                          isExternal
                          icon={<Icon name={`social/${title.toLocaleLowerCase()}`} height={5.5} width={5.5} />}
                          title={title}
                          aria-label={title}
                          to={url}
                          variant="ghost"
                        />
                      )
                  )}
                </HStack>
              )}
              {content?.length > 0 && (
                <VStack alignItems="stretch" justifyContent="flex-start" spacing={12.5}>
                  {content.map(({ key, content, title }) => (
                    <VStack key={key} alignItems="stretch" justifyContent="flex-start" spacing={12.5}>
                      {title && (
                        <Text color="grey.fog" size="sm" variant="caps">
                          {title}
                        </Text>
                      )}
                      {content && (
                        <Text as="div" color="grey.lake" letterSpacing="generous" size="sm">
                          {content}
                        </Text>
                      )}
                    </VStack>
                  ))}
                </VStack>
              )}
            </Container>
          )}
        </Box>
      </Box>
    )
  })
)

export default SectionText
